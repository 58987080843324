<template>
  <div>
    <!--filters-->
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label for="start-date">From</label>
            <b-form-datepicker
              id="start-date"
              v-model="startDate"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              reset-button
              class="mb-2"
              @input="endDate=setEndDate(startDate, endDate)"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label for="end-date">To</label>
            <b-form-datepicker
              id="end-date"
              v-model="endDate"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              reset-button
              class="mb-2"
              @input="startDate=setStartDate(startDate, endDate)"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 align-self-center"
          >
            <div class="d-flex align-items-center">
              <b-button
                variant="primary"
                :disabled="isQueryRequested"
                @click="getRequestedPeriodTourBookingList()"
              >
                <b-spinner
                  v-if="isQueryRequested"
                  small
                />
                Set Period
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!--tour booking list-->
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row class="justify-content-end">
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
              @input="debounceSearch(meta.offset=0, getRequestedQuery)"
            />
            <v-select
              v-model="tourStatus"
              :options="['SUCCESS', 'FAILED', 'CANCELLED', 'PENDING', 'HOLD']"
              class="status-filter-select"
              placeholder="Select Status"
              @input="getRequestedQuery()"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </b-col>
        </b-row>
      </div>
      <b-card-actions
        ref="tour-booking"
        no-actions
        no-body
      >
        <b-table
          id="flightBookingTable"
          :items="tourBookingList"
          :fields="tableColumns"
          :busy="isTableLoading"
          primary-key="id"
          empty-text="No matching records found"
          responsive
          show-empty
          sticky-header="685px"
          class="position-relative"
        >
          <template #table-busy>
            <div class="text-center text-primary m-2">
              <b-spinner class="align-middle" />
              <strong> Loading...</strong>
            </div>
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <feather-icon
                :id="`${data.item.id}-preview-icon`"
                icon="EyeIcon"
                size="16"
                class="mx-1 cursor-pointer"
                @click="getTourData(data.item, 'details')"
              />
              <b-dropdown
                v-if="['HOLD', 'PENDING', 'SUCCESS'].includes(data.item.status)"
                variant="link"
                toggle-class="p-0"
                class="action-menu"
                no-caret
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  v-if="data.item.status === 'HOLD'"
                  v-b-modal.tour-modal
                  @click="getRequestedAction({ product_reference: data.item.booking_id }, 'Send Payment Link', 'send payment link for',
                                             'user_journey/offline_invoice/assisted_payment/',
                                             'post', false)"
                >
                  <feather-icon icon="SendIcon" />
                  <span class="align-middle ml-50">Send Payment Link</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.status === 'PENDING' || data.item.status === 'HOLD'"
                  v-b-modal.tour-modal
                  @click="getRequestedAction({ invoice_id: data.item.invoice.invoice_id }, 'Confirm Booking', 'confirm', 'user_journey/booking_confirmation/', 'post', false)"
                >
                  <feather-icon icon="CheckCircleIcon" />
                  <span class="align-middle ml-50">Confirm Booking</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.status === 'SUCCESS'"
                  v-b-modal.tour-modal
                  @click="getRequestedAction({ invoice_id: data.item.invoice.invoice_id }, 'Download Voucher', 'download voucher for', `user_journey/invoice/${data.item.invoice.invoice_id}/voucher_download/`, 'get', true)"
                >
                  <feather-icon icon="CheckCircleIcon" />
                  <span class="align-middle ml-50">Download Voucher</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="formatClassName(data.item.status)"
            >
              {{ data.item.status }}
            </b-badge>
          </template>
          <template #cell(user)="data">
            <p class="mb-0">
              {{ data.item.user.email }}
            </p>
            <small class="text-muted">{{ data.item.user.phone }}</small>
          </template>
          <template #cell(tour_name)="data">
            {{ data.item.availability.length > 0 ?
              `${data.item.availability[0].parent_availability.option.tour.name}` : '' }}
          </template>
          <template #cell(option_name)="data">
            <span
              v-for="(availability, a) in data.item.availability"
              :key="a"
            >
              {{ availability.parent_availability ? availability.parent_availability.option.name
                : '' }}
              {{ availability.child_availability && availability.child_availability.option
                ? `- ${availability.child_availability.option.name}` : '' }} x
              {{ availability.quantity }}
            </span>
          </template>
          <template #cell(add-on)="data">
            <template v-if="data.item.add_on_list.length > 0">
              <span
                v-for="(addon,a) in data.item.add_on_list"
                :key="a"
                class="d-block"
              >
                {{ addon.add_on ? addon.add_on.name : '' }} x {{ addon.quantity }}
              </span>
            </template>
            <template v-else>
              <span>N/A</span>
            </template>
          </template>
          <template #cell(tour_date)="data">
            {{ formatDate(data.item.availability[0].parent_availability.date) }}
          </template>
          <template #cell(travellers)="data">
            {{ calculateTotalTravellers(data.item, data.item.availability) }}
          </template>
          <template
            #cell(vendor)="data"
          >
            <div v-if="data.item && data.item.vendor">
              <p class="mb-0">
                {{ data.item.vendor.name }}
              </p>
              <small class="d-block">{{ data.item.vendor.email }}</small>
              <small>{{ data.item.vendor.phone_number }}</small>
            </div>
            <div v-else>
              N/A
            </div>
          </template>
        </b-table>
      </b-card-actions>
      <!--pagination-->
      <TablePagination
        ref="pagination"
        :limit="meta.limit"
        :list-data="tourBookingList"
        :total="meta.total"
        :offset="meta.offset"
        @paginate="paginate"
      />
    </b-card>
    <TourDetailsView
      :tour-data="tourData"
      :request-type="sidebarContent"
      @getTourBookingList="getTourBookingList"
    />
    <TourActions
      :modal-data="tourActionData"
      @getBookingList="getTourBookingList"
    />
  </div>
</template>

<script>
/* eslint-disable no-nested-ternary */
import moment from 'moment'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import api from '@/libs/api'
import helper from '@/mixins/helper'
import calendar from '@/mixins/calendar'
import TourDetailsView from '@/views/bookings/tour/TourDetailsView.vue'
import TablePagination from '@/components/common/TablePagination.vue'
import TourActions from '@/views/bookings/tour/TourActions.vue'

export default {
  name: 'TourBookingList',
  components: {
    TourDetailsView,
    TourActions,
    TablePagination,
    BCardActions,
  },
  mixins: [helper, calendar],
  data() {
    return {
      startDate: moment(new Date()).subtract(30, 'days').format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      sidebarContent: '',
      isQueryRequested: false,
      tourBookingList: [],
      tourData: {},
      searchQuery: '',
      tourStatus: '',
      isDownload: false,
      isTableLoading: false,
      meta: {
        offset: 0,
        limit: 10,
        total: 0,
      },
      tourActionData: {},
      tableColumns: [
        { key: 'actions', label: '' },
        {
          key: 'booking_id', label: 'BOOKING ID', sortable: true, thClass: 'md',
        },
        {
          key: 'created_at',
          label: 'BOOKING TIME',
          sortable: true,
          formatter: value => this.formatDate(value),
          thClass: 'md',
        },
        {
          key: 'status',
          label: 'STATUS',
          thClass: 'sm',
        },
        {
          key: 'user',
          label: 'User',
          thClass: 'md',
        },
        {
          key: 'tour',
          label: 'Tour Name',
          thClass: 'lg',
          formatter: value => value.name || 'N/A',
        },
        {
          key: 'tour_date',
          label: 'Tour Date',
          thClass: 'md',
        },
        {
          key: 'travellers',
          label: 'Travellers',
          thClass: 'md',
        },
        {
          key: 'invoice.refund_amount',
          label: 'Refund',
          thClass: 'md',
          formatter: (value, _, item) => `${item.invoice.to_currency_code || this.getCurrencyCode(item.sell_region)} ${Number(value).toLocaleString()}`,
        },
        {
          key: 'cancellation_date',
          label: 'Cancellation Date',
          thClass: 'md',
          formatter: value => (value ? this.formatDate(value) : 'N/A'),
        },
      ],
    }
  },
  computed: {
    filterQuery() {
      const startDate = this.startDate ? `&date_from=${this.startDate}` : ''
      const endDate = this.endDate ? `&date_to=${this.endDate}` : ''
      const searchString = this.searchQuery ? `&search=${this.searchQuery}` : ''
      const status = this.tourStatus ? `&status=${this.tourStatus}` : ''
      const download = this.isDownload ? '&download=true' : ''
      return `${startDate}${endDate}${searchString}${status}${download}`
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.startDate = null
      this.endDate = null
      this.searchQuery = this.$route.query.id
    }
    this.getTourBookingList()
  },
  methods: {
    getRequestedPeriodTourBookingList() {
      this.isQueryRequested = true
      this.getRequestedQuery()
    },
    getRequestedQuery() {
      this.meta.offset = 0
      this.getTourBookingList()
    },
    getTourBookingList() {
      this.isTableLoading = !this.isDownload
      this.$refs['tour-booking'].showLoading = !this.isDownload
      api.getData(`tour/go_biz/booking/?offset=${this.meta.offset}${this.filterQuery}`, true).then(response => {
        if (response.data.status) {
          if (this.isDownload) {
            setTimeout(() => {
              window.open(response.data.result.url, '_blank')
              this.isDownload = false
            }, 5000)
          } else {
            this.setData(response.data.result)
          }
        } else {
          this.tourBookingList = []
          this.notificationMessage('danger', 'XIcon', 'Error',
            response.data.error.message)
        }
      }).catch().finally(() => {
        this.resetSpinner()
        this.isTableLoading = false
        this.$refs['tour-booking'].showLoading = false
      })
    },
    resetSpinner() {
      this.isQueryRequested = false
    },
    setData(data) {
      this.tourBookingList = data.results
      this.meta = data.meta
    },
    getTourData(data, type) {
      this.tourData = data
      this.sidebarContent = type
      this.$root.$emit('bv::toggle::collapse', 'details-view')
    },
    downloadTourBookingList() {
      this.isDownload = true
      this.getTourBookingList()
    },
    paginate(page) {
      this.meta.offset = page
      this.getTourBookingList()
    },
    calculateTotalTravellers(tour, availability) {
      let adult = 0
      let infant = 0
      let child = 0
      availability.forEach(a => {
        adult += a.adult
        child += a.child
        infant += a.infant
      })
      if (tour.adult_pax > adult) {
        adult = tour.adult_pax
      }
      if (tour.child_pax > child) {
        child = tour.child_pax
      }
      if (tour.infant_pax > infant) {
        infant = tour.infant_pax
      }
      const adultText = adult ? adult > 1 ? `${adult} Adults` : `${adult} Adult` : ''
      const childText = child ? child > 1 ? `,${child} Children` : `,${child} Child` : ''
      const infantText = infant ? infant > 1 ? `,${infant} Infants` : `,${infant} Infant` : ''
      return `${adultText} ${childText} ${infantText}`
    },
    getRequestedAction(data, title, text, apiEndpoint, apiMethod, isDownload) {
      this.tourActionData = {
        data,
        title,
        text,
        apiEndpoint,
        apiMethod,
        isDownload,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
